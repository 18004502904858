import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { ChangeEvent, useState } from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router";
import { getTableHeadersValidationPage } from "../../constants/tableConstants";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import useFetch from "../../service/useFetch";
import {
  PaginationWithSort,
  SpeciesValidationData,
} from "../../types/DataTypes";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import TableCellWithDiv from "../../components/tableCell/TableCellWithDiv";
import { highlightText } from "../../utils/highlightText";
import { useAuth } from "../../context/AuthContext";
import useDebounce from "../../hooks/useDebounce";

export default function ValidationPage() {
  const { token, user } = useAuth();
  const navigate = useNavigate();
  const [sort, setSort] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useState<string>("");
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setDebouncedValue(event.target.value);
    setPage(0);
  };

  useDebounce(() => {
    setSearch(debouncedValue);
  }, debouncedValue);
  const { responseData, loading, error } = useFetch<
    PaginationWithSort<SpeciesValidationData>
  >({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/validation/get-species-in-reports?page=${page}&limit=${rowsPerPage}&search=${search}&sort_by=${sort}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  const handleNavigate = (latinName: string, englishName: string) => {
    navigate(`/validation/${latinName}`, { state: { latinName, englishName } });
  };
  const handleSort = (sortName: string) => {
    setSort(sortName);
  };
  const TABLE_HEADERS_VALIDATION = getTableHeadersValidationPage(
    <CheckIcon sx={{ color: "green" }} fontSize="large" />,
    <ClearIcon sx={{ color: "red" }} fontSize="large" />
  );
  return (
    <Container>
      <Grid container spacing={0} direction="column">
        <Grid container>
          <Grid item pb={2} xs={12} md={4} display="flex" gap={1}>
            <OutlinedInput
              fullWidth
              id="search"
              name="search"
              placeholder="Search species..."
              type="text"
              size="small"
              onChange={handleSearch}
              value={debouncedValue}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} alignItems="center" justifyContent="center">
          <TableContainer component={Paper}>
            <Box
              sx={{
                overflow: "auto",
                width: "100%",
                display: "table",
                tableLayout: "fixed",
              }}
            >
              <Table sx={{ minWidth: 600 }} aria-label="table">
                <TableHead>
                  <TableRow>
                    {TABLE_HEADERS_VALIDATION.map(({ sortName, label }) => (
                      <TableCell
                        className="tableCell"
                        key={label}
                        onClick={() => handleSort(sortName)}
                        sx={{ cursor: "pointer" }}
                        align="center"
                      >
                        {label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!responseData && (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {responseData?.results.map((data: SpeciesValidationData) => {
                    if (data.validations.length > 0) {
                      return (
                        <TableRow
                          key={data.latin}
                          onClick={() =>
                            handleNavigate(data.latin, data.english)
                          }
                          sx={{
                            cursor: "pointer",
                            "&.MuiTableRow-root:hover": {
                              backgroundColor: "rgba(206, 133, 20, 0.12)",
                            },
                          }}
                        >
                          <TableCellWithDiv
                            width="auto"
                            content={highlightText(data.latin, search)}
                          />
                          <TableCellWithDiv
                            width="auto"
                            content={highlightText(data.english, search)}
                          />
                          <TableCellWithDiv
                            width="auto"
                            content={
                              data.validations.filter(
                                (rec) => rec.validation_status === true
                              ).length
                            }
                          />
                          <TableCellWithDiv
                            width="auto"
                            content={
                              data.validations.filter(
                                (rec) => rec.validation_status === false
                              ).length
                            }
                          />
                          <TableCellWithDiv
                            width="auto"
                            content={
                              data.validations.filter(
                                (rec) => rec.validation_status !== null
                              ).length === data.validations.length ? (
                                <DoneIcon
                                  fontSize="medium"
                                  sx={{ color: "green" }}
                                />
                              ) : (
                                <span
                                  style={{
                                    color:
                                      data.validations.filter(
                                        (rec) => rec.validation_status !== null
                                      ).length > 0
                                        ? "orange"
                                        : "red",
                                  }}
                                >
                                  {`${
                                    data.validations.filter(
                                      (rec) => rec.validation_status !== null
                                    ).length
                                  }/${data.validations.length || 10}`}
                                </span>
                              )
                            }
                          />
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                </TableBody>
                <PaginationComponent
                  count={responseData?.metadata.count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </Table>
            </Box>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}
