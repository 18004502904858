import React, { useState } from "react";

import { useFormik } from "formik";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";

import TextField from "@mui/material/TextField/TextField";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import InputFieldComponent from "../../components/input/InputField";
import useMutate from "../../service/useMutate";
import { validationSchemaCreateUser } from "../../validation/validationSchemaCreateUser";
import { notify } from "../../utils/notify";
import { ErrorTypes } from "../../types/DataTypes";
import { SELECT_ROLE } from "../../constants/selectRoleConstants";
import BackComponent from "../../components/back/BackComponent";
import useFetch from "../../service/useFetch";
import {
  FarmSelectionObject,
  OrganizationSelectionObject,
} from "../../types/PropsTypes";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { userRole } from "../../constants/userRoleConstants";
import InputAutoComplete from "../../components/input/InputAutoComplete";

function UserCreatePage() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { token } = useAuth();

  const { responseData } = useFetch<FarmSelectionObject[]>({
    url: `${REACT_APP_API_URL}/farms/role-filtered`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const { responseData: responseDataOrganization } = useFetch<
    OrganizationSelectionObject[]
  >({
    url: `${REACT_APP_API_URL}/organization/role-filtered`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const { fetchData, error, loading } = useMutate({
    url: `${REACT_APP_API_URL}/users`,
    method: "POST",
    headers: requestHeaderToken(token),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      role: "",
      farm: "",
      organization: "",
      is_active: true,
    },
    validationSchema: validationSchemaCreateUser,
    onSubmit: (values) => {
      fetchData(values)
        .then(() => {
          setIsSubmitted(true);
        })
        .catch((error: ErrorTypes) => {
          notify("Something went wrong!", error);
        });
    },
  });
  const handleChangeFarm = (event: any, newValue: any) => {
    formik.setFieldValue("farm", newValue?.id ?? "");
  };
  const handleChangeOrganization = (event: any, newValue: any) => {
    formik.setFieldValue("organization", newValue?.id ?? "");
  };
  return (
    <Container>
      <BackComponent />
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={5} alignItems="center" justifyContent="center">
          {isSubmitted ? (
            <Box mt={10}>
              <Card elevation={3}>
                <CardContent>
                  <Box sx={{ textAlign: "center", p: 3 }}>
                    <Typography variant="h6">
                      User successfully added! An invitation is sent to their
                      email.
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Card elevation={3}>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ textAlign: "center", p: 3 }}>
                  <CardHeader title="Add new user" />
                  <Divider />
                  <CardContent>
                    <Box sx={{ textAlign: "left" }}>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="name"
                          type="text"
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          size="small"
                          name="name"
                          label="Name"
                          value={formik.values?.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <FormHelperText error>
                            {formik.errors.name}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <InputFieldComponent
                          id="email"
                          type="email"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          fullWidth
                          name="email"
                          label="Email"
                          value={formik.values?.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {((formik.touched.email && formik.errors.email) ||
                          (error && error.message.includes("Email"))) && (
                          <FormHelperText error>
                            {formik.errors.email || error?.message}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <TextField
                          select
                          fullWidth
                          name="role"
                          size="small"
                          id="role"
                          label="Role"
                          value={formik.values?.role}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          {SELECT_ROLE.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        {formik.touched.role && formik.errors.role && (
                          <FormHelperText error>
                            {formik.errors.role}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <InputAutoComplete
                          value={null}
                          onChange={handleChangeFarm}
                          onBlur={formik.handleBlur}
                          label="Farm"
                          disabled={
                            formik.values?.role === userRole.admin ||
                            formik.values?.role ===
                              userRole.organization_admin ||
                            formik.values?.role === userRole.validator
                          }
                          options={responseData ?? []}
                          getOptionLabel={(farm) =>
                            (farm as FarmSelectionObject).name ?? ""
                          }
                        />
                        {formik.touched.farm && formik.errors.farm && (
                          <FormHelperText error>
                            {formik.errors.farm}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mb={4} mt={4}>
                        <InputAutoComplete
                          value={null}
                          onChange={handleChangeOrganization}
                          onBlur={formik.handleBlur}
                          label="Organization"
                          disabled={
                            formik.values?.role === userRole.admin ||
                            formik.values?.role === userRole.farm_admin ||
                            formik.values?.role === userRole.validator
                          }
                          options={responseDataOrganization ?? []}
                          getOptionLabel={(organization) =>
                            (organization as OrganizationSelectionObject)
                              .name ?? ""
                          }
                        />
                        {formik.touched.organization &&
                          formik.errors.organization && (
                            <FormHelperText error>
                              {formik.errors.organization}
                            </FormHelperText>
                          )}
                      </Box>
                    </Box>
                    <Button
                      disabled={!(formik.isValid && formik.dirty)}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                    >
                      Save
                    </Button>
                  </CardContent>
                </Box>
              </form>
              {loading && (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
export default UserCreatePage;
