import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import BackComponent from "../../components/back/BackComponent";
import WavFilePlayer from "./components/WavFilePlayer";
import {
  ErrorTypes,
  PaginationWithSort,
  Recording,
  SelectionType,
  SpeciesDataResponseType,
  Validation,
} from "../../types/DataTypes";
import useFetch from "../../service/useFetch";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import { useAuth } from "../../context/AuthContext";
import useMutate from "../../service/useMutate";
import { notify } from "../../utils/notify";
import ValidationOptions from "./components/ValidationOptions";
import SpeciesValidationTable from "./components/SpeciesValidationTable";
import FileUploadButton from "./components/FileUploadButton";

export default function SpeciesValidationPage() {
  const location = useLocation();
  const { latinName, englishName } = location.state || {};
  const { token } = useAuth();
  const [playhead, setPlayhead] = useState(0);
  const [resetFlag, setResetFlag] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [comment, setComment] = useState<string>("");
  const [selectedValidation, setSelectedValidation] =
    useState<Validation | null>(null);
  const [selectedRecording, setSelectedRecording] = useState<Recording | null>(
    null
  );
  const [validationStatus, setValidationStatus] = useState<boolean | null>(
    null
  );
  const [uploadedFileContent, setUploadedFileContent] = useState<Validation[]>(
    []
  );
  const [speciesHeard, setSpeciesHeard] =
    useState<SpeciesDataResponseType | null>(null);
  const [type, setType] = useState<SelectionType | undefined>(undefined);
  const {
    responseData: species,
    loading,
    error,
  } = useFetch<PaginationWithSort<SpeciesDataResponseType>>({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/species?page=${0}&limit=${999}&sort_by=latin`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const { responseData: recordings, loading: recordingsLoading } = useFetch<
    Validation[]
  >({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/validation/${latinName}/find-for-validator`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const { fetchData: createValidations, loading: creatingValidations } =
    useMutate({
      url: `${REACT_APP_API_URL}/validation/${latinName}`,
      method: "POST",
      headers: requestHeaderToken(token),
    });

  const {
    fetchData: createMultipleValidationsForSpecificRecordings,
    loading: creatingMultipleValidationsForSpecificRecordings,
  } = useMutate({
    url: `${REACT_APP_API_URL}/validation/create-specific`,
    method: "POST",
    headers: requestHeaderToken(token),
  });

  const { fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/validation/${selectedValidation?.id}`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });

  const onSubmit = () => {
    if (selectedRowIndex === null || validationStatus === null) {
      notify("Please select a row and validation status first.");
      return;
    }
    /* eslint-disable no-underscore-dangle */
    const selectedData = recordings?.[selectedRowIndex];
    const payload: any = {
      validation_status: validationStatus,
      comment,
      species_heard: validationStatus === false ? speciesHeard?._id : null,
    };

    fetchData(payload)
      .then(() => {
        notify("Recording site successfully updated!");
        window.location.reload();
      })
      .catch((error: ErrorTypes) => {
        notify(`Request failed: ${error.message}`);
      });
  };

  /* Quick fix - disable generating random validations for now
   */
  // useEffect(() => {
  //   if (recordings?.length === 0) {
  //     createValidations({}).then((res) => {
  //       if (res.length) {
  //         window.location.reload();
  //       }
  //     });
  //   }
  // }, [recordingsLoading]);

  const clickNextHandler = () => {
    setPlayhead(playhead + 1);
  };

  const clickPreviousHandler = () => {
    setPlayhead(playhead - 1);
  };

  const handleHeardSpeciesChange = (species: any) => {
    setSpeciesHeard(species);
  };

  const getHeardSpecies = (id: string) => {
    return species?.results.find((sp) => sp._id === id) || null;
  };

  const handleOnRowClick = (index: number, validation: Validation) => {
    setIsRowSelected(true);
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
    setSelectedValidation(validation);
    setSelectedRecording({
      url: validation.recording,
      offset: validation.offset,
    });
    if (validation) {
      setComment(validation.comment);
      setValidationStatus(validation.validation_status);
      setSpeciesHeard(getHeardSpecies(validation.species_heard));
      setResetFlag((prev) => !prev);
    }
  };

  useEffect(() => {
    console.log(uploadedFileContent);
    if (uploadedFileContent.length !== 0) {
      const formattedData = uploadedFileContent.map((item) => ({
        offset: item.offset,
        predicted_species: item.predicted_species,
        recording: item.recording,
      }));

      createMultipleValidationsForSpecificRecordings({
        validations: formattedData,
      })
        .then((response) => {
          console.log("Validations created successfully:", response);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error creating validations:", error);
        });
    }
  }, [uploadedFileContent]);

  const handleRadioChange = (status: boolean) => {
    setValidationStatus(status);
    if (status === true) {
      setSpeciesHeard(null);
      setResetFlag((prev) => !prev);
    }
  };

  useEffect(() => {
    if (validationStatus === true) {
      setSpeciesHeard(null);
    }
  }, [validationStatus]);
  const fileUploadedHandler = (fileContent: Validation[]) => {
    setUploadedFileContent(fileContent);
  };

  return (
    <Container>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 3 }}
      >
        <Grid item>
          <BackComponent />
        </Grid>
        <Grid item>
          <FileUploadButton
            mouseEnterHandler={setType}
            fileUploadedHandler={fileUploadedHandler}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {englishName} ({latinName})
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <WavFilePlayer
            recording={selectedRecording}
            clickNextHandler={clickNextHandler}
            clickPreviousHandler={clickPreviousHandler}
          />
        </Grid>
      </Grid>
      <ValidationOptions
        validationStatus={validationStatus}
        handleRadioChange={handleRadioChange}
        comment={comment}
        setComment={setComment}
        speciesHeard={speciesHeard}
        species={species}
        handleHeardSpeciesChange={handleHeardSpeciesChange}
        isRowSelected={isRowSelected}
        resetFlag={resetFlag}
      />
      <Grid container justifyContent="center" spacing={2} sx={{ mb: 3, mt: 2 }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
            disabled={selectedRowIndex === null || validationStatus === null}
          >
            Update Validation
          </Button>
        </Grid>
      </Grid>
      {recordingsLoading || creatingValidations ? (
        <Box
          sx={{
            display: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid item xs={12} md={8} alignItems="center" justifyContent="center">
          <SpeciesValidationTable
            recordings={recordings}
            selectedRowIndex={selectedRowIndex}
            handleOnRowClick={handleOnRowClick}
          />
        </Grid>
      )}
    </Container>
  );
}
