export const TABLE_HEADERS_FARMS = [
  {
    label: "Name",
    sortName: "name",
  },
  {
    label: "Address",
    sortName: "address",
  },
  {
    label: "County",
    sortName: "county",
  },
  {
    label: "Post code",
    sortName: "post_code",
  },
  {
    label: "CPH number",
    sortName: "CPH_number",
  },
];
export const TABLE_HEADERS_FARMS_REPORTS = [
  {
    label: "Farm",
    sortName: "name",
  },
  {
    label: "Owner",
    sortName: "contact.name",
  },
  {
    label: "Email",
    sortName: "contact.email",
  },
  {
    label: "What3words",
    sortName: "what3words",
  },
  {
    label: "Recording sites",
    sortName: "",
  },
];

export const TABLE_HEADERS_USERS = [
  {
    label: "Name",
    sortName: "name",
  },
  {
    label: "Email",
    sortName: "email",
  },
  {
    label: "Role",
    sortName: "role",
  },
  {
    // Empty cell heading for reset verification link
    label: "",
    sortName: "",
  },
];

export const TABLE_HEADERS_ORGANIZATION = [
  {
    label: "Name",
    sortName: "name",
  },
  {
    label: "Website URL",
    sortName: "website_url",
  },
  { label: "Date created", sortName: "date_created" },
];

export const TABLE_HEADERS_BENCHMARK_GROUPS = [
  {
    label: "Name",
    sortName: "name",
  },
  {
    label: "Organization",
    sortName: "organization",
  },
  { label: "Date created", sortName: "date_created" },
];

export const TABLE_HEADERS_SHARE_REQUEST = [
  { label: "Farm", sortName: "farm" },
  {
    label: "Organization",
    sortName: "organization",
  },
  { label: "Date created", sortName: "date_created" },
  { label: "Status", sortName: "status" },
];

export const TABLE_HEADERS_SHARE_REQUEST_ORGANIZATION = [
  { label: "Farm" },
  {
    label: "User",
  },
  { label: "Date created" },
  { label: "Status" },
];

export const TABLE_FARM_SHARE_REQUEST = [
  { label: "Name" },
  { label: "Post code" },
  { label: "Farm" },
];

export const TABLE_HEADERS_SPECIES_LIST = [
  { label: "Latin name", sortName: "latin" },
  { label: "English name", sortName: "english" },
  { label: "Habitat", sortName: "habitat" },
  { label: "Trophic", sortName: "trophic" },
  { label: "Trophic level", sortName: "trophic_level" },
  { label: "Bio indicators", sortName: "bio_indicators" },
  { label: "Assemblages", sortName: "assemblages" },
  { label: "Food sources", sortName: "food_sources" },
  { label: "Rating", sortName: "rating" },
];

export const getTableHeadersValidationPage = (correct: any, incorrect: any) => [
  { label: "Latin name", sortName: "latin" },
  { label: "English name", sortName: "english" },
  { label: correct, sortName: "latin" },
  { label: incorrect, sortName: "latin" },
  { label: "Validation complete", sortName: "latin" },
];

export const TABLE_HEADERS_SPECIES_VALIDATION = [
  { label: "No." },
  { label: "Correct Identification?" },
  { label: "Notes" },
];
