import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useEffect } from "react";
import useFetch from "../../../service/useFetch";
import { WavFilePlayerProps } from "../../../types/PropsTypes";
import { REACT_APP_API_URL } from "../../../constants/apiConstants";
import { requestHeaderToken } from "../../../utils/requestHeaderToken";
import { useAuth } from "../../../context/AuthContext";

export default function WavFilePlayer({
  recording,
  clickNextHandler,
  clickPreviousHandler,
}: WavFilePlayerProps) {
  const { token } = useAuth();
  const offset = recording?.offset || "0";
  let defaultStartTime = "00:00:00";
  if (offset !== undefined) {
    defaultStartTime = `00:00:${`0${parseInt(offset, 10)}`.slice(-2)}`;
  }

  const { responseData: signedUrl } = useFetch<string>({
    url: `${REACT_APP_API_URL}/validation/recording-signed-url?url=${recording?.url}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  useEffect(() => {
    console.log(signedUrl);
  }, [signedUrl]);

  return (
    <AudioPlayer
      src={`${signedUrl}#t=${defaultStartTime}`}
      showSkipControls
      onClickNext={clickNextHandler}
      onClickPrevious={clickPreviousHandler}
      progressJumpSteps={{ backward: 5000, forward: 5000 }}
    />
  );
}
