import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  CircularProgress,
  Card,
  Box,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { SpeciesValidationTableProps } from "../../../types/PropsTypes";
import { TABLE_HEADERS_SPECIES_VALIDATION } from "../../../constants/tableConstants";
import { Validation } from "../../../types/DataTypes";
import TableCellWithDiv from "../../../components/tableCell/TableCellWithDiv";

/* eslint-disable no-nested-ternary */
export default function SpeciesValidationTable({
  recordings,
  selectedRowIndex,
  handleOnRowClick,
}: SpeciesValidationTableProps) {
  return (
    <TableContainer component={Card}>
      <Box
        sx={{
          overflow: "auto",
          width: "100%",
          display: "table",
          tableLayout: "fixed",
        }}
      >
        <Table sx={{ minWidth: 600 }} aria-label="species validation table">
          <TableHead>
            <TableRow>
              {TABLE_HEADERS_SPECIES_VALIDATION.map(({ label }) => (
                <TableCell className="tableCell" key={label} align="center">
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!recordings && (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {recordings?.map((data: Validation, index: number) => (
              <TableRow
                key={index}
                onClick={() => handleOnRowClick(index, data)}
                sx={{
                  backgroundColor:
                    selectedRowIndex === index
                      ? "rgba(206, 133, 20, 0.2)"
                      : "transparent",
                  cursor: "pointer",
                  "&.MuiTableRow-root:hover": {
                    backgroundColor: "rgba(206, 133, 20, 0.12)",
                  },
                }}
              >
                <TableCellWithDiv width="auto" content={index + 1} />
                <TableCellWithDiv
                  width="auto"
                  content={
                    data.validation_status ===
                    null ? null : data.validation_status ? (
                      <DoneIcon fontSize="medium" sx={{ color: "green" }} />
                    ) : (
                      <ClearIcon fontSize="medium" sx={{ color: "red" }} />
                    )
                  }
                />
                <TableCellWithDiv width="auto" content={data.comment} />
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={100} sx={{ textAlign: "right", py: 2 }}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 500, color: "text.secondary" }}
                >
                  {
                    recordings?.filter((rec) => rec.validation_status !== null)
                      .length
                  }{" "}
                  out of {recordings?.length} validated
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </TableContainer>
  );
}
