/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactNode } from "react";
import { TrophicEnum } from "../constants/enums";

/* eslint-disable no-unused-vars */
export type UserRole =
  | "ADMIN"
  | "FARM_ADMIN"
  | "ORGANIZATION_ADMIN"
  | "VALIDATOR";
export type User = {
  sub: string;
  email: string;
  role: UserRole;
  iat: number;
  exp: number;
};
export type MenuItem = {
  textByRole: {
    FARM_ADMIN?: string;
    ORGANIZATION_ADMIN?: string;
    ADMIN?: string;
    VALIDATOR?: string;
    default: string;
  };
  icon: ReactNode;
  path: string;
  allowed: (UserRole | "*")[];
  children?: MenuItem[];
  disabled?: boolean;
};
export type MenuItems = MenuItem[];

export type DataResponseType = {
  id: string;
  name: string;
  email: string;
  role: string;
  is_active: boolean;
  is_verified: boolean;
  organization: string;
  farm: string;
};

export type ErrorTypes = {
  statusCode?: number;
  message?: any;
  error?: string;
};
export interface ApiResponseTypeFetch<T> {
  responseData: T | undefined;
  loading: boolean;
  error?: ErrorTypes;
  refresh?: any;
  enabled?: boolean;
  onSuccess?: (res?: T) => void;
}
export type ApiResponseTypeMutate = {
  fetchData: (data: any) => Promise<any>;
  loading: boolean;
  error?: ErrorTypes;
};

export type LoginResponse = {
  token: string;
};

export type ContactType = {
  name: string;
  email: string;
  phone: string;
};

export type ShareData = {
  government: boolean;
  customer: boolean;
};

export type Livestock = {
  [cattle: string]: string | null;
};

export const waterKeys = ["river", "lake", "wetland"] as const;

export type WaterKeys = typeof waterKeys[number];

export type Waters = {
  [key in WaterKeys]?: "yes" | "no" | null | true | false;
};

export type LandUsage = {
  natural_grass: number | string;
  grass_seed_mix: number | string;
  planted_herbal_ley: number | string;
  silvopasture: number | string;
  forage_or_crops: number | string;
  arable: number | string;
  left_for_nature: number | string;
  other: string | number;
};

export type AdditionalData = {
  farm_size: number | string;
  sustainability_systems: Array<string>;
  retail_suppliers: Array<string>;
  dairy_suppliers: Array<string>;
  meat_suppliers: Array<string>;
  beverage_suppliers: Array<string>;
  livestock_stocking_density: Livestock;
  natural_water: Waters;
  land_usage: LandUsage;
};

export type Order = "asc" | "desc" | undefined;

export type MetaData = {
  limit: number;
  page: number;
  count: number;
  sort_by: string;
  order: Order;
};

export type LocationType = {
  longitude: number;
  latitude: number;
};
export type DataFarmsResponseType = {
  id: string;
  name: string;
  CPH_number: string;
  SBI_number: string;
  address: string;
  county: string;
  post_code: string;
  main_interest: string;
  farmerId: string;
  share_data: ShareData;
  what3words: string;
  location: LocationType;
  is_active: boolean;
  benchmark_group: string;
  additional_data: AdditionalData;
};

export type PaginationWithSort<T> = {
  metadata: MetaData;
  results: Array<T>;
};

export type ResponseRecordingSite = {
  id: string;
  name: string;
  description: string;
  location: LocationType;
  memory_card_id: string;
  is_active?: boolean;
};

export type SpeciesReportType = {
  latin: string;
  english: string;
  is_endangered: boolean;
  n: number;
  song_url?: string;
  is_vertivore: boolean;
  rating: number;
};

export type AggregatedReportResponseType = {
  species: Array<SpeciesReportType>;
  from: string;
  to: string;
};
export type ChirupRatingResponseType = {
  monitoring: boolean;
  top_quartile: boolean;
  improved: boolean;
};

export type TrendDataType = Record<string, number>;
export type TrendType = {
  farm: TrendDataType;
  benchmark_group: TrendDataType;
};
export type BencmarkGroupReportResponseType = {
  id: string;
  name: string;
  unique_species: Record<string, string[]>;
  trend: TrendDataType;
};

export type RecordingSitesType = {
  location: LocationType;
  id: string;
  name: string;
  what3words: string;
};

export type ReportFarmDataType = DataFarmsResponseType & {
  benchmark_group: BencmarkGroupReportResponseType;
  recording_sites: RecordingSitesType[];
};

export type FarmDetailsResponseType = {
  species_present: number;
  red_list: number;
  unique_at_site: number;
  species_percent: number;
  unique_at_site_species: string[];
};

export type FarmReportResponseType = {
  site_details: FarmDetailsResponseType;
  farm: ReportFarmDataType;
  report: AggregatedReportResponseType;
  chirrup_rating: ChirupRatingResponseType;
  trend: TrendType;
};

export type OrganizationDataResponseType = {
  id: string;
  name: string;
  description: string;
  website_url: string;
  created_at: string;
  is_active: boolean;
};

export type BenchmarkGroupDataResponseType = {
  id: string;
  name: string;
  description: string;
  website_url: string;
  created_at: string;
  is_active: boolean;
  unique_species: object;
  trend: object;
  organization: string;
  is_default: boolean;
};

export type RequestUserResponseType = {
  id: string;
  name: string;
};

export type ShareRequestsResponseType = {
  CPH_number: string;
  created_at: string;
  farm: string;
  farm_name: string;
  id: string;
  organization: OrganizationDataResponseType;
  request_user: RequestUserResponseType;
  status: string;
  updated_at: string;
};

export type ColorStatus = {
  APPROVED: "success";
  REJECTED: "error";
  REVOKED: "default";
  PENDING: "primary";
  APPROVED_LIMITED: "success";
  FUNDING: "success";
};

export type ResponseRecordingDates = {
  date: Array<string>;
  processed_dates: Array<string>;
  uploaded: {
    n: number;
    year: string;
    f: number;
    p: number;
  }[];
};

export type DataFarmerListResponseType = {
  is_active: boolean;
  id: string;
  name: string;
  email: string;
};

export type FarmSearchResponseType = {
  id: string;
  name: string;
  post_code: string;
  CPH_number: string;
};

export type ActiveReportDataType = {
  farm: string;
  benchmark_group: string;
  recording_sites: string[];
  benchmark_groups?: string[];
  from: string;
  to: string;
  year: number;
};
export type ReportTime = {
  p: number;
  ftp: number;
};

export type FarmsInBGDataType = {
  id: string;
  name: string;
  county: string;
  post_code: string;
  address: string;
  CPH_number: string;
};

export type SpeciesChartFarm = {
  id: string;
  name: string;
  diversity_number: number;
  red_list: number;
  has_access: boolean;
  has_limited_access: boolean;
};
export interface FarmData {
  name: string;
  CPH_number: string;
  SBI_number: string;
  address: string;
  county: string;
  post_code: string;
  is_active: boolean;
  main_interest: string;
}
export interface FullFarmData {
  name: string;
  CPH_number: string;
  SBI_number: string;
  address: string;
  county: string;
  post_code: string;
  is_active: boolean;
  main_interest: string;
  additional_data: AdditionalData;
}
export type Best14Days = {
  end_date: string | null;
  start_date: string | null;
};

export type AssemblageResponseType = {
  id: string;
  assemblage: string;
};

export type BioIndicatorResponseType = {
  id: string;
  bio_indicator: string;
};

export type FoodSourceResponseType = {
  id: string;
  food_source: string;
};

export type TrophicResponseType = {
  id: string;
  trophic: string;
};

export type HabitatResponseType = {
  id: string;
  habitat: string;
};

export type SpeciesDataResponseType = {
  _id: string;
  latin: string;
  english: string;
  international: string;
  trophics: TrophicResponseType[];
  habitat: HabitatResponseType;
  song_url: string;
  img_url: string;
  is_endangered: boolean;
  rating: number;
  trophic_level: number;
  assemblages: AssemblageResponseType[];
  bio_indicators: BioIndicatorResponseType[];
  food_sources: FoodSourceResponseType[];
};

export type SpeciesDatasetReportDataType = {
  species: SpeciesReportType[];
  onlyIn: number;
  onlyInSpecies: SpeciesReportType[];
};

export type SpeciesComparisonReportDataType = {
  datasetA: SpeciesDatasetReportDataType;
  datasetB: SpeciesDatasetReportDataType;
  commonSpecies: SpeciesReportType[];
};

export type SiteToSiteReportDataType = {
  farm1: string;
  farm2: string;
  recording_sites: string[];
  from: string;
  to: string;
  year: number;
};

export type YearToYearReportDataType = {
  farm: string;
  recording_site: string;
  year1: number;
  year2: number;
};

export type Recording = {
  url: string;
  offset?: string;
};

export type Validation = {
  id: string;
  validator: string;
  recording: string;
  validation_status: boolean;
  comment: string;
  species_heard: string;
  predicted_species: string;
  offset: string;
};

export type SpeciesValidationData = {
  _id: string;
  latin: string;
  english: string;
  validations: Validation[];
};
export enum SelectionType {
  Recordings = 1,
  Filenames,
  Upload,
  Table,
}

export type FileUploadRecording = {
  recordingId: string;
  filename: string;
  offset?: string;
  latinName?: string;
  certainty?: number;
  farm?: string;
  locations?: string[];
};
