import {
  Grid,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import InputAutoComplete from "../../../components/input/InputAutoComplete"; // Adjust the import path
import { ValidationOptionsProps } from "../../../types/PropsTypes";

export default function ValidationOptions({
  validationStatus,
  comment,
  setComment,
  speciesHeard,
  species,
  handleRadioChange,
  handleHeardSpeciesChange,
  isRowSelected,
  resetFlag,
}: ValidationOptionsProps) {
  return (
    <Grid
      sx={{
        pointerEvents: isRowSelected ? "auto" : "none",
        opacity: isRowSelected ? 1 : 0.5,
      }}
    >
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={validationStatus === true}
                onChange={() => handleRadioChange(true)}
              />
            }
            label="Correct identification"
            sx={{ color: "green" }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            label="Add a Comment"
            placeholder="Type your comment here..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            disabled={validationStatus === null}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={validationStatus === false}
                onChange={() => handleRadioChange(false)}
              />
            }
            label="Incorrect identification"
            sx={{ color: "red" }}
          />
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="body1" align="right">
                Species heard:
              </Typography>
            </Grid>
            <Grid item xs>
              <InputAutoComplete
                fullWidth
                size="small"
                id="site1_id"
                value={validationStatus ? null : speciesHeard}
                options={species?.results || []}
                getOptionLabel={(option: any) =>
                  `${option.latin} (${option.english})`
                }
                onChange={(e, selectedSpecies) =>
                  handleHeardSpeciesChange(selectedSpecies)
                }
                label="Heard species"
                disabled={validationStatus !== false}
                key={resetFlag ? "reset" : "normal"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
