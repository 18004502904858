import { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Papa from "papaparse";
import { Tooltip } from "@mui/material";
import {
  FileUploadRecording,
  SelectionType,
  Validation,
} from "../../../types/DataTypes";
import { FileUploadButtonProps } from "../../../types/PropsTypes";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function FileUploadButton({
  fileUploadedHandler,
  mouseEnterHandler,
}: FileUploadButtonProps) {
  const [filename, setFilename] = useState("");
  const handleResetInput = () => {
    setFilename("");
  };

  const handleFileUploaded = (files: FileList | null) => {
    console.log("uploading file");
    if (files !== null) {
      Papa.parse<FileUploadRecording>(files[0], {
        header: true,
        complete(results: Papa.ParseResult<FileUploadRecording>) {
          console.log("results: ", results);
          if (results?.data !== undefined) {
            const parsedResults = results.data.map((recording) => {
              let certainty;
              if (recording.certainty) {
                try {
                  certainty = parseFloat(recording.certainty.toString());
                } catch (e) {
                  console.log(e);
                }
              }
              return {
                recording: `${recording.recordingId}/${recording?.filename}`,
                offset: recording?.offset,
                predicted_species: recording?.latinName,
              };
            });
            fileUploadedHandler(parsedResults as Validation[]);
          } else {
            fileUploadedHandler([]);
          }
        },
      });
    }
  };

  return (
    <Box
      sx={{
        pt: 2,
        pb: 2,
        width: "100%",
        color: "primary.main",
        bgcolor: "white",
      }}
      onMouseEnter={() => mouseEnterHandler(SelectionType.Upload)}
    >
      <Tooltip
        title="File format: recordingId,filename,offset,latinName,certainty"
        arrow
      >
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload WAV filenames
          <VisuallyHiddenInput
            type="file"
            accept=".csv"
            value={filename}
            onClick={handleResetInput}
            onChange={(event) => handleFileUploaded(event.target.files)}
          />
        </Button>
      </Tooltip>
    </Box>
  );
}
